const $ = require('jquery');
import swup from '../animations/Swup';

const mount = function() {
    const container = $('#portfolio').html();
    $('.portfolio').append(container);

    $('.toggle-galerie').click(function() {
        if ($('.portfolio').css('opacity') != 0) {
            $('.portfolio').css({
                opacity: 0,
                zIndex: -1
            });
        } else {
            $('.portfolio').css({
                opacity: 1,
                zIndex: 9999
            });
        }
    });
}

const unMount = function() {
    $('.portfolio').html("");
}

mount();

swup.on('contentReplaced', mount);
swup.on('willReplaceContent', unMount);
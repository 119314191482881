const $ = require('jquery');
import swup from '../animations/Swup';

const mount = function() {
    $(document).ready(function() {
        $('.slider-home-real-content').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: true,
            fade: true,
            asNavFor: '.slider-home-real-views',
            autoplay: true,
            autoplaySpeed: 2000
        });
        $('.slider-home-real-views').slick({
            slidesToShow: $( window ).width() > 992 ? 2 : 1,
            slidesToScroll: 1,
            asNavFor: '.slider-home-real-content',
            arrows: false,
            focusOnSelect: true,
            rtl: true,
            autoplay: true,
            autoplaySpeed: 2000
        });
        $('.slider-actus-content').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: true,
            fade: true,
            asNavFor: '.slider-actus-views'
        });
        $('.slider-actus-views').slick({
            asNavFor: '.slider-actus-content',
            arrows: false,
            focusOnSelect: true
        });
        $('.real-slider').slick({
            arrows: true,
            dots: true
        });
    });
}

mount();

swup.on('contentReplaced', mount);
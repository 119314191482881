import DirectionReveal from 'direction-reveal';
import swup from './Swup';

const mount = function() {
  DirectionReveal({
    selector: '.direction-reveal',
    itemSelector: '.direction-reveal__card',
    animationName: 'swing',
    enableTouch: true,
    touchThreshold: 250
  });
}

mount();

swup.on('contentReplaced', mount);
const $ = require('jquery');
import swup from '../animations/Swup';

const mount = function() {
    $(document).ready(function(){
        $('.single-stat span').each(function() {
            const element = $(this);
            const value = $(this).data('value');


            if (isScrolledIntoView(element)) {
                animate(element, value);
            } else {
                $(window).scroll(function() {
                    if (isScrolledIntoView(element) && !element.text()) {
                        animate(element, value);
                    }
                })
            }
        });
    });
}
  
function isScrolledIntoView(elem){
    var $elem = $(elem);
    var $window = $(window);

    var docViewTop = $window.scrollTop();
    var docViewBottom = docViewTop + $window.height();

    var elemTop = $elem.offset().top;
    var elemBottom = elemTop + $elem.height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}

function animate(element, value) {
    $({someValue: 0}).animate({someValue: value}, {
        duration: 3000,
        easing:'swing',
        step: function() {
            element.text(Math.round(this.someValue));
        }
    }).promise().done(function () {
        element.text(value);
    });
}

mount();

swup.on('contentReplaced', mount);
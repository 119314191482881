const $ = require('jquery');
import swup from '../animations/Swup';

function displayPosts(slug, title) {
    $('.categories h2').text(title);
    
    $('.categories .card-single').each(function() {
        const cats = $(this).data('cats');
    
        if (cats.includes(slug)) $(this).fadeIn("slow");
        else $(this).hide();
    });
}

const mount = function() {
    displayPosts($('.sidebar__list__cat .selected').data('cat'), $('.sidebar__list__cat .selected').data('name'));

    if ($('.sidebar__list__cat').is(':visible')) {
        $('.sidebar__list__cat .item').click(function() {
            const item = $(this);
            $('.sidebar__list__cat .item').removeClass('selected');
            item.addClass('selected');

            displayPosts(item.data('cat'), item.data('name'));
        });
    } else {
        $('#sidebar__list__cat__resp').change(function() {
            const item = $(this).find(":selected");

            displayPosts(item.val(), item.data('name'));
        })
    }
}

mount();

swup.on('contentReplaced', mount);
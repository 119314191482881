const $ = require('jquery');
import swup from '../animations/Swup';

const getContainerWidth = function() {
    return $('.cards .container').width();
};

const defineSizeBlockPeople = function() {
    let divisor = 5;

    if ($( window ).width() < 576) {
        divisor = 1;
    } else if ($( window ).width() < 768) {
        divisor = 2;
    } else if ($( window ).width() < 992) {
        divisor = 3.5;
    }

    const size = getContainerWidth() / divisor;
    $('.block__people').css({
        width: size,
        height: size
    });
}

const mount = function() {
    defineSizeBlockPeople();

    $(window).resize(function(){
        defineSizeBlockPeople();
    });

    $('.block__people').each(function() {
        $(this).css('background-image', `url(${$(this).attr('data-img')})`);
    });
}

mount();

swup.on('contentReplaced', mount);
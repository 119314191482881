const $ = require('jquery');
import swup from '../animations/Swup';

const mount = function() {
    $(document).ready(function() {
        if (window.location.pathname === '/') $('#pubModal').modal('show');
    });
}

mount();

swup.on('contentReplaced', mount);
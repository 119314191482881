const $ = require('jquery');
import swup from '../animations/Swup';

const mount = function() {
    $(document).ready(function() {
	    if ($('section.besoins').length) {
            function setPosition(widthSlice, leftSlice) {
                $('.slice').css({
                    'width': widthSlice,
                    'margin-left': leftSlice
                });
            }
        
            function select(selectItem, isSelect) {
                const widthSlice = $(selectItem).width();
                const leftSlice = $(selectItem).offset().left;
        
                setPosition(widthSlice, leftSlice);
                $('.besoins h2.item-besoin').removeClass("text-warning");
                $('.besoins h2.item-besoin').addClass("text-white");
        
                if (isSelect) {
                    $('.besoins .item-besoin.def-selected').removeClass("selected");
                }
            }
        
            function unSelect(isSelect) {
                setPosition(widthSliceDefault, leftSliceDefault);
                if (!isSelect) {
                    $('.besoins h2.item-besoin').removeClass("text-white");
                    $('.besoins h2.item-besoin').addClass("text-warning");
                } else {
                    $('.besoins .item-besoin.def-selected').addClass("selected");
                }
            }
            
            let isSelect = false;
            let widthSliceDefault = $('.besoins h2.item-besoin').width();
            let leftSliceDefault = $('.besoins h2.item-besoin').offset().left;
            const heightSlice = $('.besoins').height();
            $('.slice').css('height', heightSlice);
        
            $('.besoins .block-item.desktop').each(function() {
                if ($(this).hasClass("selected")) {
                    select(this, false);
                    widthSliceDefault = $(this).width();
                    leftSliceDefault = $(this).offset().left;
                    $(this).addClass("def-selected");
                    isSelect = true;
                }
            });
            
            if (!isSelect) unSelect(isSelect);
            
            $('.besoins .block-item').hover(function() {
                select(this, isSelect);
            }, function() {
                unSelect(isSelect);
            });
        }
    });
}

mount();

swup.on('contentReplaced', mount);
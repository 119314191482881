import ScrollReveal from 'scrollreveal';
import swup from './Swup';
const $ = require('jquery');

const mount = function() {

    const fadeIn =  {
        distance: '110%',
        origin: 'bottom',
        delay: 200,
        duration: 500,
        // reset: true
    };
    const fadeRight =  {
        distance: '110%',
        origin: 'right',
        delay: 400,
        duration: 800,
        // reset: true
    };
    const fadeLeft =  {
        distance: '110%',
        origin: 'left',
        delay: 200,
        duration: 800,
        // reset: true
    };

    ScrollReveal().reveal('.fade-in', fadeIn);
    ScrollReveal().reveal('.fade-right', fadeRight);
    ScrollReveal().reveal('.fade-left', fadeLeft);
    
    if ($( window ).width() > 992) {
        ScrollReveal().reveal('.fade-md-in', fadeIn);
        ScrollReveal().reveal('.fade-md-right', fadeRight);
        ScrollReveal().reveal('.fade-md-left', fadeLeft);
    }
}

mount();

swup.on('contentReplaced', mount);
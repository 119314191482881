const $ = require('jquery');
import swup from '../animations/Swup';

const mount = function() {
	$(document).ready(function() {
		$('.menu-item').click(function() {
			let toggler = false;
			if ($(this).hasClass("current")) {
				toggler = true;
			}

			$('.menu-item').removeClass("current");
			$('.menu-item ul').hide();
			
			if (!toggler) {
				$(this).addClass("current");
				$(this).find('ul').show();
			} else {
				$(this).find('ul').hide();
			}
		});

		// Define separate on headers
		const heightSliderContent = $('.block-slider-content').height();
		const height = heightSliderContent * 0.75;
		$('.separate').css("height", height + "px");
		$('.block-slider-content').css("top", height + "px");

		const left = $('.space-before-slider_content').width();
		$('.slick-dots').css('left', left + 'px');

	});
}

const unMount = function() {
	$(document).ready(function() {
		$('.separate').css("height", "0px");
		$('.menu-item').removeClass("current");
		$('.menu-item').find('ul').hide();
		$('#toggle').prop("checked", false);
	});
}

mount();

swup.on('contentReplaced', mount);
swup.on('willReplaceContent', unMount);
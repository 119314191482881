import Swup from 'swup';

const mount = function() {
    document.querySelectorAll('[href]').forEach(link => {
        link.addEventListener("click", () => {
            document.documentElement.scrollTop = 0;
        });
    });
}

mount();

const swup = new Swup({
    animateHistoryBrowsing: true,
});

swup.on('contentReplaced', mount);

export default swup;
/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// Sass
require('../scss/main.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
require('jquery');
require('popper.js');
require('bootstrap');

require('./animations/Swup');

require('slick-carousel');

// Fontawesome
require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.min.js');


require('./components/loading');
require('./components/frise-besoins');
require('./components/sliders');
require('./components/header');
require('./components/stats');
require('./components/popInFirstCon');
require('./components/playerVideo');
require('./components/categories');
require('./components/modal');
require('./components/trombi');

require('./animations/SrcollReveal');
require('./animations/DirectionReveal');


// Uncomment this to active the second navbar on scroll

// window.onscroll = () => {
//     let currentScrollPos = window.pageYOffset;
//     if (currentScrollPos > 50) {
//         document.getElementById("second-nav-main").style.top = "0";
//     } else {
//         document.getElementById("second-nav-main").style.top = "-70px";
//     }
// }